@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slide img {
    width: 20rem;
    margin: 0 auto;
}

@media (max-width: 1024px) {
    .slide img {
        width: 100%;
        height: 100%;
    }
}

.carousel-height {
    height: 150px;
}
@media (max-width: 768px) {
    .carousel-height {
        height: 250px;
    }
}
@media (max-width: 320px) {
    .carousel-height {
        height: 300px;
    }
}
.slide {
    transform: scale(0.8);
    transition: transform 300ms;
    opacity: 0.5;
}

.activeSlide {
    transform: scale(1.1);
    opacity: 1;
}

.arrow {
    background-color: #fff;
    position: absolute;
    cursor: pointer;
    z-index: 10;
}

.arrow svg {
    transition: color 300ms;
}

.arrow svg:hover {
    color: #68edff;
}

.next {
    right: 0%;
    top: 50%;
}

.prev {
    left: 0%;
    top: 50%;
}
:focus-visible {
    outline: none !important;
}
